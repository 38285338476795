import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";

import AuthSlice from "./slices/AuthSlice";
import ConfigSlice from "./slices/ConfigSlice";
import DarkMode from "./slices/DarkMode";
import UISlice from "./slices/UISlice";
import CataloguesSlice from "./slices/catalogues/CataloguesSlice";
import MembershipSlice from "./slices/operator/MembershipSlice";
import UserSlice from "./slices/operator/UserSlice";
import GuestsSlice from "./slices/user/GuestsSlice";
import InvitationsSlice from "./slices/user/InvitationsSlice";
import ModulesSlice from "./slices/ModulesSlice";

const rootReducer = combineReducers({
    auth: AuthSlice,
    ui: UISlice,
    config: ConfigSlice,
    modules: ModulesSlice,
    users: UserSlice,
    catalogues: CataloguesSlice,
    colorMode: DarkMode,
    membership: MembershipSlice,
    guests: GuestsSlice,
    invitations: InvitationsSlice,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk],
});
