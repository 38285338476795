import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes
} from "react-router-dom";
import "../../sass/app.scss";
import { LoadingApp } from "../components/LoadingApp";
import { PrivateRoute } from "../components/router/PrivateRoute";
import { PublicRoute } from "../components/router/PublicRoute";
import { renewSession } from "../redux/slices/AuthSlice";
import { uiSetEnviromentVersions } from "../redux/slices/UISlice";
import Loader from "./layout/layoutcomponent/loaders";

const Layout = React.lazy(() => import("./layout/App"));

const Dashboard = React.lazy(() => import("./home/Dashboard"));
const LoginScreen = React.lazy(() => import("./auth/LoginScreen"));
const RecoverPassword = React.lazy(() => import("./auth/RecoverPassword"));
const ChangePassword = React.lazy(() => import("./auth/ChangePassword"));

const MembershipScreen = React.lazy(() =>
    import("./operator/memberships/MembershipScreen")
);
const AdminScreen = React.lazy(() => import("./operator/admin/AdminScreen"));
const AdminDetail = React.lazy(() => import("./operator/admin/AdminDetail"));
const InvitationsScreen = React.lazy(() =>
    import("./user/invitations/InvitationsScreen")
);
const PartnersScreen = React.lazy(() =>
    import("./operator/admin/PartnersScreen")
);
const GuestsScreen = React.lazy(() => import("./operator/admin/GuestsScreen"));

export const AppRouter = () => {
    const { checking, user } = useSelector((store) => store.auth);

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(uiSetEnviromentVersions());
        dispatch(renewSession());
    }, []);

    if (!checking) {
        return <Loader />;
    }

    return (
        <BrowserRouter>
            <React.Suspense fallback={<Loader />}>
                <Routes>
                    <Route
                        path={`/login`}
                        element={<PublicRoute component={LoginScreen} />}
                    />
                    <Route
                        path={`/login/operador`}
                        element={<PublicRoute component={LoginScreen} />}
                    />
                    {/* <Route
                        path={`/login/operador`}
                        element={<PublicRoute component={LoginScreen} />}
                    /> */}
                    <Route
                        path={`/recuperar/contraseña`}
                        element={<PublicRoute component={RecoverPassword} />}
                    />
                    <Route
                        path={`/cambiar/contraseña`}
                        element={<PublicRoute component={ChangePassword} />}
                    />

                    <Route path={"/"} element={<Layout />}>
                        <Route
                            path={`/app/inicio`}
                            element={<PrivateRoute component={Dashboard} />}
                        />
                        <Route
                            path={`/app/membresias`}
                            element={
                                <PrivateRoute component={MembershipScreen} />
                            }
                        />
                        <Route
                            path={`/app/invitaciones`}
                            element={
                                <PrivateRoute component={InvitationsScreen} />
                            }
                        />
                        <Route
                            path={`/app/mis-invitados`}
                            element={
                                <PrivateRoute component={InvitationsScreen} />
                            }
                        />
                        <Route
                            path={`/app/usuarios`}
                            element={<PrivateRoute component={AdminScreen} />}
                        />
                        <Route
                            path={`/app/usuario/:user_id`}
                            element={<PrivateRoute component={AdminDetail} />}
                        />
                        <Route
                            path={`/app/usuarios/socios`}
                            element={
                                <PrivateRoute component={PartnersScreen} />
                            }
                        />
                        <Route
                            path={`/app/usuarios/invitados`}
                            element={<PrivateRoute component={GuestsScreen} />}
                        />

                        {/* Default */}
                        <Route
                            path={`/`}
                            element={<PrivateRoute component={Dashboard} />}
                        />
                    </Route>
                    <Route
                        path="/*"
                        element={<Navigate to={"/app/inicio"} />}
                    />
                </Routes>
            </React.Suspense>
            <LoadingApp />
        </BrowserRouter>
    );
};
