import { useSelector } from "react-redux";

export const useHasPermission = () => {
    const { user, session } = useSelector((state) => state.auth);
    const isLogged = !!user;

    const hasPermision = (permissions = []) => {
        if (!isLogged) {
            console.error("Hook useHasPermission:", "Debes iniciar sesión.");
            return false;
        }
        if (permissions.length == 0) {
            return true;
        }

        const { modules } = user;
        let permissionsArray = [];

        if (typeof permissions == "string") {
            permissionsArray.push(permissions);
        } else {
            if (typeof permissions == "object") {
                permissionsArray = permissions;
            } else {
                console.error(
                    "Hook useHasPermission:",
                    "Debes pasar un ´array´ o un ´string´"
                );
                return false;
            }
        }

        let modules_code = modules.map((m) => m.code);

        for (let i = 0; i < modules_code.length; i++) {
            for (let j = 0; j < permissionsArray.length; j++) {
                if (modules_code[i] == permissionsArray[j]) {
                    return true;
                }
            }
        }

        return false;
    };

    const canRead = (permissionCode) => {
        const canDoit = hasPermision([permissionCode]);
        if (canDoit) {
            const { modules } = user;
            const module = modules.find((m) => m.code === permissionCode);
            return Boolean(module.pivot.read);
        }
        return false;
    };

    const canWrite = (permissionCode) => {
        const canDoit = hasPermision([permissionCode]);
        if (canDoit) {
            const { modules } = user;
            const module = modules.find((m) => m.code === permissionCode);
            return Boolean(module.pivot.write);
        }
        return false;
    };

    const canEdit = (permissionCode) => {
        const canDoit = hasPermision([permissionCode]);
        if (canDoit) {
            const { modules } = user;
            const module = modules.find((m) => m.code === permissionCode);
            return Boolean(module.pivot.edit);
        }
        return false;
    };

    const canDelete = (permissionCode) => {
        const canDoit = hasPermision([permissionCode]);
        if (canDoit) {
            const { modules } = user;
            const module = modules.find((m) => m.code === permissionCode);
            return Boolean(module.pivot.delete);
        }
        return false;
    };

    const canReport = (permissionCode) => {
        const canDoit = hasPermision([permissionCode]);
        if (canDoit) {
            const { modules } = user;
            const module = modules.find((m) => m.code === permissionCode);
            return Boolean(module.pivot.report);
        }
        return false;
    };

    return {
        hasPermision,
        canRead,
        canWrite,
        canEdit,
        canDelete,
        canReport,
        isLogged,
        session,
    };
};
