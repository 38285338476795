import { createSlice } from "@reduxjs/toolkit";
import { Call } from "../../helpers/fetch";
import { errorHandler } from "../../helpers/errorHandler";
import { startUILoading, stopUILoading } from "./UISlice";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
        total: 0,
    },
    list: [],
};

const ModulesSlice = createSlice({
    name: "ModulesSlice",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        clearList: (state) => {
            state.list = initialState.list;
        },
    },
});

export const { setPagination, clearPagination, setList, clearList } =
    ModulesSlice.actions;

export default ModulesSlice.reducer;

// Actions
export const startShowModules = ({
    page = 1,
    query = "",
    number_rows = 10,
    asList = 0,
    user_id,
}) => {
    return async (dispatch) => {
        if (!asList) {
            dispatch(startUILoading());
        }

        const params = new URLSearchParams();

        if (asList) {
            params.set("asList", asList);
        } else {
            params.set("page", page);
            params.set("q", query);
            params.set("per_page", number_rows);
        }
        params.set("user_id", user_id);

        const resp = await Call(`modules/show`, "GET", params.toString());

        if (resp.success) {
            if (asList) {
                dispatch(setList(resp.success));
            } else {
                dispatch(setPagination(resp.success));
            }
        } else {
            errorHandler(resp);
        }

        if (!asList) {
            dispatch(stopUILoading());
        }
    };
};
