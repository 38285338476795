import { createSlice } from "@reduxjs/toolkit";
import { Call } from "../../helpers/fetch";
import { startUILoading, stopUILoading } from "./UISlice";
import { errorHandler } from "../../helpers/errorHandler";
import Swal from "sweetalert2";

const initialState = {
    checking: false,
    session: null,
    user: null,
};
const AuthSlice = createSlice({
    name: "AuthSlice",
    initialState,
    reducers: {
        stopChecking: (state) => {
            state.checking = true;
        },
        setLogin: (state, action) => {
            state.session = action.payload.session;
            state.user = action.payload.user;
        },
        setlLogout: (state) => {
            state.session = null;
            state.user = null;
        },
    },
});

export const { setLogin, setlLogout, stopChecking } = AuthSlice.actions;

export default AuthSlice.reducer;

export const setloginSession = (
    email = null,
    membership = null,
    password,
    setErrors = null
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        let resp;

        if (email) {
            //* Administradores/Operadores
            email = email.toLowerCase();
            resp = await Call("auth/login", "POST", { email, password }, false);
        } else if (membership) {
            //* Socios
            resp = await Call(
                "auth/loginCustomer",
                "POST",
                { membership, password },
                false
            );
        }

        if (resp.success) {
            const { access_token, user, session } = resp.success;
            localStorage.setItem("token", access_token);
            dispatch(setLogin({ user, session }));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};
export const renewSession = () => {
    return async (dispatch) => {
        if (!localStorage.getItem("token")) {
            dispatch(stopChecking());
            dispatch(stopUILoading());
            return;
        }
        dispatch(startUILoading());

        const resp = await Call("auth/renew");

        if (resp.success) {
            const { access_token, user, session } = resp.success;
            localStorage.setItem("token", access_token);
            localStorage.setItem("session", session);
            dispatch(setLogin({ user, session }));
        }

        dispatch(stopChecking());
        dispatch(stopUILoading());
    };
};

export const startForgotMyPassword = (email, setErrors = null) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        email = email.toLowerCase().trim();

        const resp = await Call(
            "auth/password/email",
            "POST",
            { email },
            false
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: resp.success,
                icon: "success",
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startRecoveryPassword = (
    email,
    password,
    confirm_password,
    navigate,
    setErrors = null
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const token = urlParams.get("token");

        const resp = await Call(
            "auth/password/reset",
            "POST",
            { password, confirm_password, token, email },
            false
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: resp.success.msg,
                icon: "success",
            });
            navigate("/login");
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const logout = (navigate = () => {}) => {
    return async (dispatch) => {
        await Call("auth/logout", "PATCH");
        dispatch(setlLogout());
        localStorage.clear();
        navigate("/login");
    };
};
