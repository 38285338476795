import PropTypes from "prop-types";
import React from "react";
import { Navigate } from "react-router-dom";
import { useHasPermission } from "../../hooks/useHasPermission";

export const PrivateRoute = ({ component: Component, permissions = null }) => {
    const { hasPermision, isLogged } = useHasPermission();

    const isAccesible = () => {
        if (permissions === null) {
            return true;
        }

        return hasPermision(permissions);
    };

    return isLogged ? (
        <>{isAccesible() ? <Component /> : <Navigate to={"/login"} />}</>
    ) : (
        <Navigate to={"/login"} />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.object.isRequired,
    permissions: PropTypes.any,
};
