import Swal from "sweetalert2";

export const cancelConfirmModal = async (callback = () => {}) => {
    const result = await Swal.fire({
        title: "¿Está seguro de cancelar?",
        icon: "warning",
        text: "¡No podrá revertir esta acción!",
        showCancelButton: true,
        cancelButtonText: "Regresar",
        confirmButtonText: "¡Sí, cancelar!",
        confirmButtonColor: "#2e4a6c",
        cancelButtonColor: "#6c757d",
        input: "text",
        inputLabel:
            "Escriba la palabra 'cancelar' para confirmar esta operación.",
        inputAttributes: {
            autocapitalize: "off",
        },
        allowOutsideClick: false,
        allowEnterKey: false,
    });

    if (result.value === "cancelar") {
        await callback();
    } else if (result.value) {
        Swal.fire("Operación no cancelada", "", "info");
    }
};
