import { createSlice } from "@reduxjs/toolkit";
import {
    Call,
    CallWithFormData,
    CallWithFormDataFile,
    downloadBlob,
    mergeFormData,
    objectToFormData,
} from "../../../helpers/fetch";
import Swal from "sweetalert2";
import { startUILoading, stopUILoading } from "../UISlice";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";
import { errorHandler } from "../../../helpers/errorHandler";

const initialState = {
    pagination: {
        data: [],
        per_page: 3,
    },
    paginationIncomes: {
        data: [],
        per_page: 3,
        total: 0,
    },
    list: [],
};

export const GuestsSlice = createSlice({
    name: "GuestsSlice",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setPaginationIncomes: (state, action) => {
            state.paginationIncomes = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },
        clearPaginationIncomes: (state) => {
            state.paginationIncomes = initialState.paginationIncomes;
        },
        clearState: () => {
            return initialState;
        },
    },
});
export const {
    setPagination,
    setPaginationIncomes,
    setList,
    clearPagination,
    clearPaginationIncomes,
    clearState,
} = GuestsSlice.actions;

export default GuestsSlice.reducer;

// Actions
export const startShowGuests = ({
    page = 1,
    query = "",
    number_rows = 3,
    asList = 0,
    status = null,
}) => {
    return async (dispatch, getState) => {
        if (!asList) {
            dispatch(startUILoading());
        }

        const anfitrion_id = getState().auth.user.id;

        const params = new URLSearchParams();

        if (asList) {
            params.set("asList", asList);
        } else {
            params.set("page", page);
            params.set("q", query);
            params.set("per_page", number_rows);
        }

        params.set("anfitrion_id", anfitrion_id);
        if (status) {
            params.set("status", status);
        }

        const resp = await Call(
            `customers/guests/show`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            if (asList) {
                dispatch(setList(resp.success));
            } else {
                dispatch(setPagination(resp.success));
            }
        } else {
            errorHandler(resp);
        }

        if (!asList) {
            dispatch(stopUILoading());
        }
    };
};

export const startSaveGuest = (values, callback = () => {}, setErrors) => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());

        let data = objectToFormData(values);

        const anfitrion_id = getState().auth.user.id;

        const formData = new FormData();

        formData.append("anfitrion_id", anfitrion_id);

        if (values?.files) {
            values.files.forEach((file, index) => {
                formData.append(`files[${index}]`, file);
            });
        }

        const resp = await CallWithFormData(
            `customers/guests/create`,
            "POST",
            mergeFormData(data, formData)
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Invitado registrado.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            callback(resp.success);
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startEditGuest = (
    guest_id,
    values,
    callback = () => {},
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        let data = objectToFormData(values);
        data.delete("files");
        data.delete("is_visible");

        const formData = new FormData();

        if (values?.files) {
            values.files.forEach((file, index) => {
                formData.append(`files[${index}]`, file);
            });
        }

        const resp = await CallWithFormData(
            `customers/guests/edit/${guest_id}`,
            "POST",
            mergeFormData(data, formData)
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Invitado editado.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            callback(resp.success?.id);
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startDeleteGuest = (guest_id, callback = () => {}) => {
    return async (dispatch) => {
        await deleteConfirmModal(async () => {
            dispatch(startUILoading());

            const resp = await Call(
                `customers/guests/delete/${guest_id}`,
                "DELETE"
            );

            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Inivtado eliminado.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
                callback();
            } else {
                errorHandler(resp);
            }

            dispatch(stopUILoading());
        });
    };
};

export const getIncomesByGuests = ({
    page = 1,
    query = "",
    number_rows = 10,
    exportFlag = 0,
    type = null,
    from = "",
    to = "",
    hideIncomesOut = 0,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);
        params.set("export", exportFlag);
        if (type) {
            params.set("type", type);
        }
        if (from) {
            params.set("from", from);
        }
        if (to) {
            params.set("to", to);
        }
        params.set("hideIncomesOut", hideIncomesOut);

        const functionCall = !exportFlag ? Call : CallWithFormDataFile;

        const resp = await functionCall(
            `club-lomas/income-guests`,
            "GET",
            params.toString()
        );

        if (exportFlag) {
            downloadBlob(resp, `Registro de accesos de invitados`);
        } else {
            if (resp.success) {
                dispatch(setPaginationIncomes(resp.success));
            } else {
                errorHandler(resp);
            }
        }

        dispatch(stopUILoading());
    };
};
