import { createSlice } from "@reduxjs/toolkit";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
} from "../../../helpers/fetch";
import { startUILoading, stopUILoading } from "../UISlice";
import Swal from "sweetalert2";

const initialState = {
    active: null,
    pagination: {
        data: [],
        per_page: 10,
        total: 0,
    },
    searchResults: null,
    invitationAccess: null,
};

const MembershipSlice = createSlice({
    name: "MembershipSlice",
    initialState,
    reducers: {
        setActive: (state, action) => {
            state.active = action.payload;
        },
        setActiveError: (state, action) => {
            state.active = action.payload;
        },
        setInvitationAccess: (state, action) => {
            state.invitationAccess = action.payload;
        },
        unsetActive: (state) => {
            state.active = null;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setSearchResults: (state, action) => {
            state.searchResults = action.payload;
        },
        unsetSearchResults: (state) => {
            state.searchResults = null;
        },
        unsetPagination: (state) => {
            state.pagination = initialState.pagination;
        },
        unsetInvitationAccess: (state) => {
            state.invitationAccess = initialState.invitationAccess;
        },
    },
});

export const {
    setActive,
    setActiveError,
    setInvitationAccess,
    unsetActive,
    setPagination,
    setSearchResults,
    unsetSearchResults,
    unsetPagination,
    unsetInvitationAccess,
} = MembershipSlice.actions;

export default MembershipSlice.reducer;

export const searchMembership = (membership) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("q", membership);

        const resp = await Call(
            `club-lomas/membership/search`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setSearchResults(resp.success));
        } else {
            // errorHandler(resp);
            // dispatch(setActiveError(resp.error));
        }

        dispatch(stopUILoading());
    };
};

export const getMembership = (membership, showErrorHandler = false) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(`club-lomas/membership/${membership}`, "GET");

        if (resp.success) {
            dispatch(setActive(resp.success));
        } else {
            if (showErrorHandler) {
                errorHandler(resp);
            } else {
                dispatch(setActiveError(resp.error));
            }
        }

        dispatch(stopUILoading());
    };
};

export const getRegisters = ({
    page = 1,
    number_rows = 10,
    membership,
    exportFlag = 0,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("page", page);
        params.set("per_page", number_rows);
        params.set("export", exportFlag);

        const functionCall = !exportFlag ? Call : CallWithFormDataFile;

        const resp = await functionCall(
            `club-lomas/income/${membership}`,
            "GET",
            params.toString()
        );

        if (exportFlag) {
            downloadBlob(resp, `Registro de accesos - ${membership}`);
        } else {
            if (resp.success) {
                dispatch(setPagination(resp.success));
            } else {
                errorHandler(resp);
            }
        }

        dispatch(stopUILoading());
    };
};

export const registerAccess = (membership, door, type, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`club-lomas/register-income`, "PUT", {
            membership,
            door,
            type,
        });

        if (resp.success) {
            Swal.fire({
                title: "Se registró la operación.",
                text: "Se registró la operación.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            dispatch(getRegisters({ membership }));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const registerAccessGuest = (data, callback = () => {}, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `club-lomas/register-income-guest`,
            "PUT",
            data
        );

        if (resp.success) {
            Swal.fire({
                title: "Se registró la operación.",
                text: "Se registró la operación.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            callback();
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const validateAccessCode = (values, callback = () => {}, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `club-lomas/membership/validate-access-code`,
            "PUT",
            values
        );

        if (resp.success) {
            dispatch(setInvitationAccess(resp.success));
        } else {
            errorHandler(resp, setErrors);
            //* Limpiar el state
            callback();
        }

        dispatch(stopUILoading());
    };
};
