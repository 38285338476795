import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { cancelConfirmModal } from "../../../helpers/CancelValidate";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";
import { errorHandler } from "../../../helpers/errorHandler";
import { Call } from "../../../helpers/fetch";
import { startUILoading, stopUILoading } from "../UISlice";

const initialState = {
    pagination: {
        data: [],
        per_page: 3,
    },
    list: [],
    currentMonth: null,
};

export const InvitationsSlice = createSlice({
    name: "InvitationsSlice",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        setCurrentMonth: (state, action) => {
            state.currentMonth = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },
        clearState: () => {
            return initialState;
        },
    },
});
export const {
    setPagination,
    setList,
    setCurrentMonth,
    clearPagination,
    clearState,
} = InvitationsSlice.actions;

export default InvitationsSlice.reducer;

// Actions
export const startShowInvitations = ({
    page = 1,
    query = "",
    number_rows = 3,
    asList = 0,
    status = null,
    month = null,
}) => {
    return async (dispatch, getState) => {
        if (!asList) {
            dispatch(startUILoading());
        }

        const anfitrion_id = getState().auth.user.id;

        const params = new URLSearchParams();

        params.set("anfitrion_id", anfitrion_id);

        if (asList) {
            params.set("asList", asList);
        } else {
            params.set("page", page);
            params.set("q", query);
            params.set("per_page", number_rows);
        }

        if (status) {
            params.set("status", status);
        }
        if (month) {
            params.set("month", month);
        }

        const resp = await Call(
            `customers/invitations/show`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            if (asList) {
                dispatch(setList(resp.success.invitations));
            } else {
                dispatch(setPagination(resp.success.invitations));
            }

            dispatch(setCurrentMonth(resp.success.current_month));
        } else {
            errorHandler(resp);
        }

        if (!asList) {
            dispatch(stopUILoading());
        }
    };
};

export const startSaveInvitation = (values, callback = () => {}, setErrors) => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());

        const anfitrion_id = getState().auth.user.id;

        const guest_ids = values.guest_ids
            ?.map((guest) => guest.value)
            ?.join(",");

        const data = { ...values, anfitrion_id, guest_ids };

        const resp = await Call(`customers/invitations/create`, "PUT", data);

        if (resp.success) {
            callback(resp.success);
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startEditInvitation = (
    invitation_id,
    values,
    callback = () => {},
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const guest_ids = values.guest_ids
            ?.map((guest) => guest.value)
            ?.join(",");

        const data = { ...values, guest_ids };

        const resp = await Call(
            `customers/invitations/edit/${invitation_id}`,
            "POST",
            data
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Invitación editada.",
                icon: "success",
                // toast: true,
                // position: "bottom-end",
                // timer: 8000,
            });
            callback();
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startDeleteInvitation = (invitation_id, callback = () => {}) => {
    return async (dispatch) => {
        await deleteConfirmModal(async () => {
            dispatch(startUILoading());

            const resp = await Call(
                `customers/invitations/delete/${invitation_id}`,
                "DELETE"
            );

            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Invitación eliminada.",
                    icon: "success",
                    // toast: true,
                    // position: "bottom-end",
                    // timer: 8000,
                });
                callback();
            } else {
                errorHandler(resp);
            }

            dispatch(stopUILoading());
        });
    };
};

export const startCancelInvitation = (invitation_id, callback = () => {}) => {
    return async (dispatch) => {
        await cancelConfirmModal(async () => {
            dispatch(startUILoading());

            const resp = await Call(
                `customers/invitations/cancel/${invitation_id}`,
                "DELETE"
            );

            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Invitación cancelada.",
                    icon: "success",
                    // toast: true,
                    // position: "bottom-end",
                    // timer: 8000,
                });
                callback();
            } else {
                errorHandler(resp);
            }

            dispatch(stopUILoading());
        });
    };
};

export const startSendEmail = (data) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `customers/invitations/sendEmail`,
            "POST",
            data
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Invitación enviada por correo.",
                icon: "success",
                // toast: true,
                // position: "bottom-end",
                // timer: 8000,
            });
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};
